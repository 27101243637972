:root {
  --cloud-color: rgb(0, 143, 195);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =====body=============================== */
body {
  background-color: var(--cloud-color);
}

/*=====GENERAL===========================*/
.main-content-holder {
  margin: 10%;
  margin-top: 12%;
  margin-left: 20%;
  margin-right: 20%;
}

/*===Navbar.js===== */
.willb-navbar {
  background-color: var(--cloud-color);
  background-image: url("./components/images/croppedMenu.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 150%;
  /* z-index: 1; */
  /* position: fixed; */
  /* top: 0; */
  width: 100%;
  /* background-position: center; */
}

.offcanvas-header {
  background-color: var(--cloud-color);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

/* =====Portfolio.js================================= */
#portfolio-holder {
  margin: 10%;
}
#portfolio-header {
  font-size: 500%;
  text-align: center;
}
.project-card-holder {
  /* margin-left: 10%;
  margin-right: 10%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* ============Home.js============== */
body {
  /* background-color: var(--cloud-color);*/
  background-image: url("./components/images/WindmillClouds.jpg");
  /* background-position: top; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}

#welcome-header {
  font-size: 3rem;
}

/* =====About.js================================= */

#profile-photo {
  width: 40%;
  float: left;
  margin-right: 5%;
}

#about-header {
  font-size: 500%;
}

#about-text {
  font-size: 150%;
}

/*========footer===================================*/
footer {
  bottom: 0;
  /* left: 0; */
  padding: 10px;
  position: fixed;
  text-align: center;
  width: 100%;
  background-image: url("./components/images/croppedMenu.jpg");
  background-size: cover;
}

/* ==========Resume======================== */
#resume-image {
  width: 100%;
}

/* ============flipboxes================ */

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */

/* Checkbox hack for portfolio cards. Hides checkbox */
.btnControl {
  display: none;
}

a {
  color: white;
}

.flip-box-inner-button {
  font-size: 2rem;
  color: #818181;
  /* position: absolute;
  bottom: 10%; */
}

.center-button {
  margin: 0;
  position: absolute;
  bottom: 0%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.flip-box {
  background-color: transparent;
  margin: 1%;
  width: 30%;
  height: 15vw;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-box-front {
  background-color: #bbb;
  color: black;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}

/* Style the back side */
.flip-box-back {
  background-color: #818181;
  color: white;
  align-content: center;
  transform: rotateY(180deg);
}

@media (max-width: 991px) {
  .flip-box {
    width: 100%;
    height: 50vw;
  }
  .offcanvas-body {
    background-color: var(--cloud-color);
    background-image: url("./components/images/croppedMenu.jpg");
    background-size: cover;
    font-size: 150%;
  }
  .btnControl:checked + .flip-box-inner {
    transform: rotateY(180deg);
  }
  #profile-photo {
    width: 100%;
    float: none;
    margin-right: 0%;
  }
}

@media (min-width: 992px) and (max-width: 1700px) {
  .flip-box {
    width: 48%;
    height: 24vw;
  }
}

/* to make flipboxes work on hover when on desktop */
@media (min-width: 992px) {
  /* Do a horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
}
